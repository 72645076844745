/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from "swiper";
import { motion } from 'framer-motion';

// Swiper Styling
import 'styles/swiper.css'

// Images
import Star from 'img/star.svg'
import ReviewBG from 'img/review-bg.svg'
import Quote from 'img/quote.svg'
import LinkedIn from 'img/linkedin-team.svg'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import { ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'
import ButtonDefault from 'components/elements/ButtonDefault'

const Hero = styled.section`
  background-size: 100% 475px;

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroImage = styled(Plaatjie)`
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);

  & img {
    object-position: 73% 50% !important;
    object-fit: cover !important;
  }

  @media screen and (min-width: 991px) {
    width: 100%;
    height: 350px;
  }
  
  @media screen and (max-width: 991px) {
    width: 80%;
    height: 240px;
    border-radius: 15px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const HeroContent = styled.div`
  padding-top: 250px;
`

const TeamTitle = styled(ParseContent)`
  & h2 {
    font-size: 36px;
    line-height: 45px;
    font-weight: bold;

    @media (max-width: 991px){
      font-size: 26px;
      line-height: 35px;
    }

    & strong {
      color: ${props => props.theme.color.text.blue}
    }
  }
`
const TeamImage = styled(Plaatjie)`
  border-radius: 15px 15px 0px 0px;
  height: 400px;
  width: 426px;
  object-fit: cover;
  transition: ease-in .15s;

  @media (max-width: 991px){
    width: 100%;
    height: 300px;
  }

  & img {
    object-fit: cover;
  }
`

const TeamContent = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 10px 10px;
`

const Name = styled.h4`
  font-size: 20px;
  font-weight: bold;
`

const Functie = styled.p`
  font-size: 20px;
  color: #1DC955;
  font-weight: 300;
`

const ReviewDiv = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  height: 330px;

  @media (min-width: 992px){
    height: 278px;
  }
`

const ReviewTitle = styled(ParseContent)`
  & h2 {
    font-size: 36px;
    line-height: 57px;
    letter-spacing: 0.25px;
    color: #1DC955;
    font-weight: bold;
    margin-left: 3rem;
    position: absolute;
    top: -1.7rem;
    background: #fff;
    padding: 0 20px;

    @media (max-width: 991px){
      font-size: 24px;
    }
  }
`

const Review = styled(ParseContent)`
  & p {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.25px;
    color: #003C72;

    @media (max-width: 991px){
      font-size: 17px;
      line-height: 20px;
    }
  }
`

const StyledImg = styled.img`
  opacity: 0.35;
`

const ReviewImg = styled.img`
  top: 0;
  right: 0;
  height: 278px;

  @media (max-width: 991px){
    height: 330px;
  }
`

const BenefitSection = styled.section`
  background: #F3F5F7;
`

const BenefitCTA = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  min-height: 315px;
`

const BenefitImage = styled(Plaatjie)`
  width: 455px;
  height: 333px;
  position: absolute;
  border-radius: 25px;
  z-index: 2;
  top: 30px;

  @media (max-width: 991px){
    width: 100%;
  }

  & img {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
    object-fit: cover;
  }
`

const BenefitContent = styled(ParseContent)`
  & h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.25px;
    color: #003C72;
    margin-bottom: 2rem;

    @media (max-width: 991px){
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 1rem;
    }
  }

  & p {
    color: #000000;
    margin-bottom: 2rem;
  }
`

const QuoteImg = styled(Plaatjie)`
  width: 102px;
  height: 102px;
  object-fit: cover;
  border: 5px solid #23C154;
  border-radius: 50%;

  & img {
    object-fit: cover;
  }
`

const QuoteContent = styled(ParseContent)`
  & h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.166667px;
    color: #003466;
  }
`

const QuoteAuthor = styled.p``

const TouchTitle = styled(ParseContent)`
  & h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.25px;

    & strong {
      color: #003466;
    }

  }
`

const TouchContent = styled(ParseContent)`
  & p {
    margin-bottom: 0;
  }
`

const LinkedinURL = styled.a`
  position: absolute;
  right: 20px;
`

const TeamImageHover = styled(motion.div)`
  height: 400px;
  width: 426px;
  object-fit: cover;
  background: rgba(0, 52, 102, 0.75);
  background-blend-mode: multiply;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 15px 15px 0px 0px;
  top: 0;
  display: flex;
  align-items: flex-end;
  padding: 0 2rem;
  
  & p {
    color: #fff;
    font-size: 16px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 3rem;
  }
`

const QuoteImgDiv = styled.div`
  text-align: left;

  @media (min-width: 992px){
    text-align: right;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <Hero
        className="hero-image-medium"
        style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}
      >
        <HeroContent className="container">
          <div className="row">
            <div className="mb-3 col-lg-6 px-lg-0">
              <ParseContent
                className="color-light text-center text-lg-left mb-3"
                content={acf.banner.description}
              />
              <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
            </div>
            <div className="col-lg-6 px-lg-0">
              <HeroImage image={acf.banner.image} alt={acf.banner.image.title} />
            </div>
          </div>
        </HeroContent>
      </Hero>

      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-6">
              <ParseContent content={acf.intro.description} className="mb-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <ParseContent content={acf.intro.secondDescription} />
            </div>
          </div>
        </div>
      </section>

      {acf.knowitem && (
        <KnowItem id={acf.knowitem} />
      )}

      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-8">
              <TeamTitle content={acf.team.title} />
            </div>
          </div>
          <div className="row pb-3 pb-lg-5 px-lg-5">
            {acf.team.teamleden.map(({ item }, index) => (
              <div className="col-lg-6 px-lg-5 mb-4 position-relative" key={index}>
                <TeamImage image={item.image} />
                <TeamImageHover initial={{ opacity: 0 }} whileHover={{ opacity: 1 }} className="position-absolute teamimagecontent">
                  <ParseContent content={item.description} />
                </TeamImageHover>
                <TeamContent className="p-4 position-relative">
                  {item.linkedin_url && (
                    <LinkedinURL href={item.linkedin_url} target="_blank">
                      <img src={LinkedIn} />
                    </LinkedinURL>
                  )}
                  <Name>{item.name}</Name>
                  <Functie className="mb-0">{item.functie}</Functie>
                </TeamContent>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-3 py-lg-5">
            <div className="col-lg-8">
              <ParseContent content={acf.meta.description} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-10 mx-auto">
              <ReviewDiv className="position-relative">
                <ReviewImg src={ReviewBG} className="position-absolute" />
                <ReviewTitle content={acf.reviews.title} className="position-relative" />
                <div className="h-100 d-flex align-items-center flex-wrap">
                  <Swiper navigation loop modules={[Navigation]} className="h-100">
                    {acf.reviews.review.map(({ item }, index) => {
                      const times = Array(item.stars).fill(item.stars)
                      const maxStars = 5
                      const rest = Array(maxStars - times.length).fill(maxStars - times.length)

                      return (
                        <SwiperSlide key={index} className="px-5 h-100 d-flex align-items-center">
                          <div className="px-3 px-lg-5">
                            <div className="d-flex mb-3 pb-2">
                              {times.map((item) => (
                                <img src={Star} className="mr-2" alt="Aantal sterren" />
                              ))}
                              {rest.length > 0 && rest.map((item) => (
                                <StyledImg src={Star} className="mr-2" alt="Aantal sterren" />
                              ))}
                            </div>
                            <Review content={item.description} />
                            <p>{item.author}</p>
                          </div>
                        </SwiperSlide>
                      )
                    }
                    )}
                  </Swiper>
                </div>
              </ReviewDiv>
            </div>
          </div>
        </div>
      </section>

      <BenefitSection>
        <div className="container py-lg-5">
          <div className="row py-5">
            <div className="col-lg-3">
              <BenefitImage image={acf.benefits.image} />
            </div>
            <div className="col-lg-9">
              <BenefitCTA className="d-flex py-5">
                <div className="col-lg-3 d-none d-lg-block" />
                <div className="col-lg-8 pt-3 pt-lg-0">
                  <BenefitContent content={acf.benefits.description} />
                  <ButtonDefault to={acf.benefits.button.url}>{acf.benefits.button.title}</ButtonDefault>
                </div>
              </BenefitCTA>
            </div>
          </div>
        </div>
      </BenefitSection>

      <section>
        <div className="container">
          <div className="row py-5 align-items-center">
            <QuoteImgDiv className="col-lg-2 mb-3 mb-lg-0">
              <QuoteImg image={acf.quote.image} />
            </QuoteImgDiv>
            <div className="col-lg-8">
              <img src={Quote} className="mb-3" />
              <QuoteContent content={acf.quote.description} />
              <QuoteAuthor>{acf.quote.author}</QuoteAuthor>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-5 mb-lg-0 pb-5 pb-lg-0">
          <div className="row py-3 py-lg-5 align-items-center">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <TouchTitle content={acf.getInTouch.title} />
            </div>
            <div className="col-lg-4 pl-lg-0 mb-3 mb-lg-0">
              <TouchContent content={acf.getInTouch.description} />
            </div>
            <div className="col-lg-4">
              <ButtonDefault to={acf.getInTouch.button.url}>{acf.getInTouch.button.title}</ButtonDefault>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
        }

        intro {
          description
          secondDescription: second_description
        }

        team {
          title
          teamleden {
            item {
              image {
                localFile {
                  childImageSharp{
                    gatsbyImageData(width: 750, quality: 100)
                  }
                }
              }
              name
              functie
              description
              linkedin_url
            }
          }
        }

        meta {
          description
        }

        reviews {
          title
          review {
            item {
              stars
              description
              author
            }
          }
        }

        benefits: calculate_benefit {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          description
          button {
            title
            url
          }
        }

        quote {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality:100)
              }
            }
          }
          description
          author
        }

        getInTouch: get_in_touch {
          title
          description
          button {
            title
            url
          }
        }

        knowitem
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
